@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:ital,wght@0,200;0,300;0,400;0,600;0,700;1,200;1,300;1,400;1,600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans&family=Roboto:wght@100;300;400&display=swap');



body {
    background-color: #f8f9ff;
    margin: 0;
    line-height: 1.5;
    font-family: 'Source Sans Pro', sans-serif !important;
}

 /** To enable carousel, remove this section **/
#testimonials {
     display: none;
}
/**************************************************************/

.lawyer-divider{
    margin-top: 50px;
    margin-bottom: 50px; 
}
a {
    text-decoration: none;
}

a:hover {
    text-decoration: none !important;
}
a:link {
    text-decoration: none;
}

footer {
    background-color: #333333;
    width: 100%;
    padding: 5px;
}

footer p {
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 12px;
    font-weight: 400;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: 1.5px;
    color: #ffffff;
    padding-top: 18px;
    padding-left: 40px;
    margin-top: 0;
    margin-bottom: 1rem;
}
#firm-header{
    padding-top: 50px;
    padding-bottom: 50px;
    margin-top: 40px;
}

// #contact_us > div > div > div:nth-child(1) > div:nth-child(1) > p:nth-child(3) > span.next-line {
//     display: block;
//     padding-left: 20px;
// }

#firm-header {
   background-color: #334786;
   color: #ffffff;
//    padding-left: 200px;
//    padding-right: 200px;
}
// #lawyer-section {
//    padding-left: 200px;
//    padding-right: 200px;
// }
#firm_f {
    padding-bottom: 10px;
}
#firm_aff h6 {
    margin-bottom: .5rem;
    font-weight: 600;
    line-height: 1.2;
    font-size: 1rem;
    margin-top: 0;
}
#firm_focus, #firm_aff p {
  font-size: 16px;
  font-weight: 400;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: 0.50px;
  font-family: 'Open Sans', sans-serif;
  font-family: 'Roboto', sans-serif;
  font-family: 'Source Sans Pro', sans-serif;
}
#lawyer-section {
    background-color: #f8f9ff;
    padding-top: 50px;
    padding-bottom: 50px;
}
.section_header {
    font-size: 24px;
    margin: 0 305px 20px 0;
    font-weight: 700;
    letter-spacing: 0.99px;
    font-family: 'Source Sans Pro', sans-serif;
}
#office_photo {
    width: 470px;
    height: 306px;
}
.lawyer-divider {
    background-color: #d8d8d8;
    height: 1px;
}
#lawyer-section img {
    width: 350px;
}
.lawyer-bold {
    font-weight: 700;
}

#owner {
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 12px;
    font-weight: bolder;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.83px;
    color: #333333;
    margin-bottom: 0px;
}

#contact_us{
    background-color: #5f6579;
    font-family: 'Source Sans Pro', sans-serif;
    color: #ffffff;
    padding-top: 40px;
    padding-bottom: 40px;

}
#contact_us h6 {
    font-size: 16px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.75px;
    padding-bottom: 10px;
}
p {
    margin-top: 0;
    margin-bottom: 1rem;
}
#contact_us p {
    font-size: 16px;
    font-weight: 400;
    font-family: 'Source Sans Pro', sans-serif;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 0.70px;
    color: #ffffffed;
    padding-bottom: 5px;
}
.contact_hours {
    font-weight: 900;
}
.appointment {
    font-size: 12px !important;
    color: #ffffffe0;
    margin-top: 0;
    margin-bottom: 1rem;
}
#contact_us > div > div > div:nth-child(1) > div:nth-child(1) { 
    margin-bottom: 40px;
}

#contact_us span {
    padding-right: 5px;
}
.navbar {
    height: 50px;
    background-color: #f8f9ff;
}
#navbar-container {
    background-color: #f8f9ff;
    box-shadow: 0px 2px 0px 0px #333333;
}
#basic-navbar-nav > div > a:hover, #basic-nav-dropdown:hover  {
    border-bottom: 1px solid #f6ae2d;
}

.navbar-nav .nav-link {
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.58px;
    color: #334786 !important;
}
#owner-title {
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 10px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.41px;
    color: #666666;
}
.practice-header {
    background-color: #334786;
    color: #ffffff;
    padding:40px;
    margin-top: 40px;
    padding-bottom: 20px;
    // padding-left: 15%;
}
.next-line {
    padding-left: 25px;
    display: block;
}
.h4, h4 {
    font-size: 1.5rem !important;
}
// .practice-header div {
//     width: 100%;
// }
.practice-header h6, .practice-header h4 {
    font-weight: 700;
 }
 .practice-header h6 { 
    font-size: 1rem;
    margin-bottom: .5rem;
    line-height: 1.2;
 }
 .practice-header h4 {
     padding-bottom: 20px;
     font-size: 1.5rem;
     margin-bottom: .5rem;
     line-height: 1.2;
     margin-top: 0;
 }
 .practice-quote {
     font-style: italic !important;
     padding-bottom: 10px;
 }
.practice-header p, .practice-header ul {
    font-size: 16px;
    font-weight: 400;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.38;
    letter-spacing: 0.76px;
    // width: 80%;
    color:#f8f9ffd5;
    margin-top: 0;
    margin-bottom: 1rem;
}
.form-section {
    background-color: #f8f9ff;
    padding:40px;
    padding-bottom: 100px;
    // padding-left: 15%;
    
}
.form-section h4 {
    font-weight: 700;
    font-size: 26px;
    padding-bottom: 20px;
    line-height: normal;
    letter-spacing: 0.99px;
    color: #333333;
    font-family: 'Source Sans Pro', sans-serif;
}
.form-section p { 
    font-size: 14px;
    font-weight: 400;
    line-height: 1.57;
    letter-spacing: 0.58px;
    color: #333333;
    font-family: 'Source Sans Pro', sans-serif;
    // width: 85%;
}

.form-check-label {
    margin-bottom: 0px !important;
}
.form-section p.second-heading {
    font-size: 16px;
    font-weight: 400;
    line-height: 1.38;
    letter-spacing: 0.66px;
    color: #333333;
    font-family: 'Source Sans Pro', sans-serif;
    padding-bottom: 10px;
}

.form-section label {
    font-size: 14px;
    font-weight: 700;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.58px;
    color: #333333;
    font-family: 'Source Sans Pro', sans-serif;
    display: inline-block;
    margin-bottom: .5rem;
}
.toast-header {
    background-color: #6ad966 !important;
    color: #fff !important;
}
.nav-link {
    margin-right: 18px;
}
.toast-header-text {
    padding-left: 10px;
}
.dropdown-item {
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.58px;
    color: #334786 !important;
}
#basic-navbar-nav > div > div > div {
    padding-bottom: 0px;
    padding-top: 0px;
}
#basic-navbar-nav > div > div > div > a:hover {
    background-color: #fdecce;
}
/************************* HOMEPAGE ********************************/
#welcome {
    background-color: rgba(0, 20, 78, 0.849);
    background:linear-gradient(360deg, #153580dc, #354b80e1, #24448db6, #0d3288ce), url("../assets/Hero.png");
    background-size:cover;
    background-position: center center;
    background-repeat:no-repeat;
    height: 50vh;
    display: flex;
    justify-content: center;
    align-items: center;
}
#welcome h2 {
    text-shadow: 1px 2px 4px rgba(0, 0, 0, 0.274);
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 45px;
    font-weight: 400;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: 3px;
    color: #ffffff;
    text-align: center;
    margin-bottom: 0.5em;
}

#areas-of-practice-select-label {
    color: #fffffff6;
    font-size: 20px;
    font-weight: 600;
    font-family: 'Source Sans Pro', sans-serif;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    padding-right: 1em;
    letter-spacing: 1.5px;
}
.lawyer-heading{
    margin-bottom: 30px;
    padding: 0px;
    line-height: 0.5;
}
#law-firm-header {
    padding-top: 60px;
    font-weight: 600 !important;
    font-size: 40px;
    font-family: 'Source Sans Pro', sans-serif;
}

.lawyer-heading h5 {
    font-family: 'Source Sans Pro', sans-serif;
    margin-bottom: .5rem;
    font-weight: 700;
    line-height: 1.2;
    font-size: 1.25rem;
}
.lawyer-heading h6 { 
    font-family: 'Source Sans Pro', sans-serif;
    margin-bottom: .5rem;
    font-weight: 600;
    line-height: 1.2;
    font-size: 1rem;
    margin-top: 0;
}
.lawyer-heading p {
    font-family: 'Source Sans Pro', sans-serif;
    margin-top: 0;
    margin-bottom: 1rem;
}
.lawyer-individual p {
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: 400;
    padding-bottom: 10px;
}
select.area_selection{display:inline-block}

.I-would-like-help-wi {
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 20px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.83px;
    color: #ffffff;
    text-align: center;
    padding-top: 10px;
}
.home_banner_header {
    width: 960px;
    height: 76px;
    margin:0 auto;
    padding: 100px;
}
#our_firm {
    background-color: #f8f9ff;
    // padding-left: 50px;
    // padding-right: 50px;
}
#contact-us-info a {
    color:#ffffff;
    font-weight: 400;
    font-family: 'Source Sans Pro', sans-serif;

}

#our_firm p {
    letter-spacing: 0.66px;
    line-height: 24px;
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 16px;
    font-weight: 400;
    padding-top: 15px;
}

.banner_links {
    width: 140px;
    height: 60px;
    border-radius: 4px;
    border: solid 1px #ffffff;
}
#business-formations {
    white-space: pre-line;
}

.banner_link_container {
    white-space:nowrap;
    text-align: center;
}
.banner_link_container li {
    display: inline;
    margin-left: 20px;
}

.banner_links a{
    line-height: 3.5;
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 16px;
    color: #ffffff;
    width: 100ch;
    word-break: break-all;
    text-decoration: none;
}
.banner_links {
    padding: 10px;
}

.our_firm {
    background-color: #f8f9ff;
}

.our_firm_content {
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 0.66px;
  color: #333333;
}
.firm {
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 20px;
    font-weight: bolder;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.83px;
    color: #333333;
    margin-top: 40px;
   
}
.firm, .firm_content p {
   padding-bottom: 20px;
}

.firm_content {
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: 0.66px;
    color: #333333;
}

.contact_us {
    background-color: #5f6579;
}
.contact_us_header {
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 18px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.75px;
    color: #ffffff;
}

.contact_us_content {
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.66px;
    color: #ffffff;
}

/*********************Carousel Styles *************************/

.width-auto {
    width: auto;
}

.text-lg {
    font-size: 2rem;
}

.carousel-indicators li {
    border: none;
    background: #ccc;
}
.carousel {
    position: relative;
}
#testimonials {
    background-color: #334786;
}
.carousel-indicators li {
    color: #ffffff; //this can be whatever color you want
}
#testimonials > div > div > a.carousel-control-next > span.carousel-control-next-icon {
    color: #cc2323 !important;
}
.carousel-control-next, .carousel-control-prev {
    font-size: 25px !important;
}
// .testimonial__carousel_main {
//     width:100%;
//     padding: 40px;
// }
.carousel-inner{
    height: auto;
    padding-left: 3rem!important;
    padding-right: 3rem!important;
    padding-bottom: 1.5rem!important;
    position: relative;
    width: 100%;
    overflow: hidden;
}
.carousel-item {
    position: relative;
    display: none;
    float: left;
    width: 100%;
    margin-right: -100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    transition: -webkit-transform .6s ease-in-out;
    transition: transform .6s ease-in-out;
    transition: transform .6s ease-in-out,-webkit-transform .6s ease-in-out;
}
.testimonial-item {
    width: 80%;
    padding:50px;
    height: auto;
    line-height: 1.5;
    object-fit: cover;
}
.testimonial-quote {
    color: #ffffffce;
    font-style: italic;
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 18px;
    text-align: center;
    letter-spacing: 0.66px;
}
.testimonial-author {
    font-weight: 700;
    color: #fffffff3;
    text-align: center;
}
.carousel-inner > .item > div {
    width: 100%; /* to make smaller images look full */
}
// .carousel-indicators {
//     bottom:-25px!important;
// }

// .grecaptcha-badge {
//     display: none;
//     }
 .carousel-indicators .active {
     background-color:#f6ae2d !important;
}

.carousel-control-next, .carousel-control-prev { 
    width: auto !important;
    text-decoration: none;
    background-color: transparent;
}
.carousel-control-next {
    right: 0;
}
.carousel-control-prev {
    left: 0;
}

.carousel-control-next, .carousel-control-prev {
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 15%;
    color: #fff;
    text-align: center;
    opacity: .5;
    transition: opacity .15s ease;
}

.media {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: start;
    align-items: flex-start;
}
.logo-thing {
    float:left;
}
.logo-thing p { 
    padding-left: 10px;
}
#logo-container {
    margin-top: 10px;
}
#logo-img {
    padding-left: 20px !important;
    width:200px;
}
.active {
    border-bottom: 1px solid #334786;
}
img {
    max-width: 100%;
    height: auto;
}
#area_of_practice_container > div > div {
    border-bottom: 2px solid #fff;
}
.i-frame-container {
    position: relative;
    overflow: hidden;
    padding-top: 56.25%; /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
}
  
  /* Then style the iframe to fit in the container div with full height and width */
.responsive-iframe {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 85%;
    height: 100%;
}
  

#root > section.form-section > div > div > div > form > div:nth-child(9) > div > div > textarea {
    height: 100px;
}
#root > section.form-section > div > div > div > form > button {
    background-color:#334786 !important;
    border-radius: 2px !important;
    font-size: 14px;
    width: 12%;
    border-color: none !important;
    box-shadow: none !important;
}


.dropdown-item.active, .dropdown-item:active {
    background-color: #ffffff !important;
    border: none !important;
    font-weight: 900;
    
}

.form-control {
    display: block;
    width: 100%;
    height: calc(1.5em + .75rem + 2px);
    padding: .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

form {
    margin-top: 20px;
}


.carousel-fade .carousel-item {
	opacity: 0;
	transition-duration: .3s;
	transition-property: opacity;
}

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
	opacity: 1;
}

.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
	opacity: 0;
}

.carousel-fade .carousel-item-next,
.carousel-fade .carousel-item-prev,
.carousel-fade .carousel-item.active,
.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-prev {
	transform: translateX(0);
	transform: translate3d(0, 0, 0);
}

.blockquote-footer {
    display: block;
    font-size: 16px !important;
    font-weight: 600;
    color:#fff !important;
    background-color: #334786;
    font-family: 'Source Sans Pro', sans-serif !important;
}
.block-quote {
    font-family: 'Source Sans Pro', sans-serif !important;
    font-style: italic !important;
    font-size: 16px;
    font-weight: 400;
    color: #fff;
}

#area_of_practice_container > div > div.css-wqmjsd-control {
    background:transparent !important;
    box-shadow: none !important;
}


@media only screen and (min-width: 1280px) {
    .carousel-inner {
        height: calc(4vh - -80px);/* full screen */
    }
 }

 @media only screen and (min-width: 1060px) and (max-width: 1280px) {
    .carousel-inner {
        height: calc(4vh - -80px); /* full screen */
    }
 }

 @media only screen and (min-width: 765px) and (max-width: 1060px) {
    .carousel-inner {
        height: calc(4vh - -80px); /* full screen */
    }
}

 @media only screen and (min-width: 765px) and (max-width: 1060px) {
    .carousel-inner {
        height: calc(4vh - -80px); /* full screen */
    }
 }

 @media only screen and (min-width: 500px) and (max-width: 765px) {
    .carousel-inner {
        height: calc(4vh - -100px); /* full screen */
    }
}
@media only screen and (min-width: 440px) and (max-width: 500px) {
    .carousel-inner {
        height: calc(4vh - -120px) !important; /* full screen */
    }
}

@media only screen and (min-width: 380px) and (max-width: 440px) {
    .carousel-inner {
        height: calc(4vh - -140px) !important; /* full screen */
    }
}

@media only screen and (max-width: 375px) {
    .carousel-inner {
        height: calc(4vh - -160px) !important; /* full screen */
    }
}

@media only screen and (max-width: 1280px) { 
    input.form-control, textarea{
        max-width: 500px !important;
    }
    #root > section.form-section > div > div > div > form > div > div > div {
        flex: 0 0 100% !important;
        max-width: 100% !important;
    }
}

@media only screen and (max-width: 900px) {
    .navbar {
      height: auto !important;
    }
    footer p {
        padding-left: 20px;
    }
    #firm-header {
        padding-bottom: 40px;
        padding-top: 40px;
        // padding-left: 10px;
        // padding-right: 10px;
    }
    .carousel-control-next{ 
        padding-left: 0px !important;
    }
    .carousel-control-prev { 
        padding-right: 0px !important;
    }

    .practice-header p, .practice-header ul, input.form-control {
        width: 100%;
    }
    .practice-header, .form-section {
        padding:0px !important;
    }
    .practice-header, .form-section {
        padding-top: 40px !important;
        padding-bottom: 40px !important;
    }
    // #our_firm {
    //     padding-left: 10px;
    //     padding-right: 10px;
    // }
    .logo-thing {
        float:left;
    }
    #logo-container {
        padding: 0px;
        margin-top: 10px;
    }
    iframe {
        width: 380px;
    }
    #welcome > div > div > div > h2 {
        font-size: 22px;
    }
    #lawyer-section img { 
        float: none !important;
    }
    #root > section.form-section > div > div > div > form > button { 
        width: 30%;
    }
    #lawyer-section {
        background-color: #f8f9ff;
        padding-top: 50px;
        padding-bottom: 50px;
        // padding-left: 10px;
        // padding-right: 10px;
    }
    #office_photo {
        width: 370px;
        height: 206px;
    }
    .toast {
        position: absolute !important;
        top: -173px !important;
        right: -175px !important;
        font-size: 12px;
    }
    .section_header {
        font-size: 22px;
        margin: 0;
        margin-bottom: 20px;
    }
    .carousel-item {
        -webkit-transform: translateZ(0);
        -moz-transform: translateZ(0);
    }
    .testimonial-item {
        width: 70%;
        padding: 10px;
        height: auto;
        text-align: center;
        line-height: 1.5;
    }
    #basic-navbar-nav > div > a:hover {
        border: none;
    }
    .navbar-toggler {
        border: none;
    }
    .navbar-toggler:focus,
    .navbar-toggler:active,
    .navbar-toggler-icon:focus {
        outline: none;
        box-shadow: none;
    }
    #navbar-container > nav > button > svg {
        font-size: 22px;
        color: #333333;
    }
    #basic-navbar-nav > div > a:hover, #basic-nav-dropdown.active  {
        border:none;
    }
    #basic-navbar-nav > div > a:hover, #basic-nav-dropdown:hover {
        border:none;
    }
    #basic-navbar-nav > div > div > div > a.dropdown-item.active {
        border: none !important;
    }
    #basic-navbar-nav > div > a.active {
        border: none !important;
    }
    .navbar-toggler {
        border: none !important;
    }


}

  @media only screen and (max-width: 765px) {
    .re-order .row {
      display: flex;
      display: -ms-flex;
      flex-direction: column;
    }
    .re-order .row .first {
      order: 2;
    }
    .re-order .row .second {
      order: 1;
      margin-bottom: 20px;
    }
    #area_of_practice_container {
        padding-top: 20px;
    }
    #contact_us h6 {
        padding-bottom: 0px !important;
    }
    #contact_us p {
        padding-top: 5px;
    }
    #law-firm-location {
        padding-top: 40px;
    }
    .i-frame-container { 
        margin-bottom: 5px;
    }
    .form-group {
        padding-top: 5px;
    }
    .i-frame-container {
        margin-top: 5px;
    }
    label.form-label {
        padding-top: 20px !important;
    }
    // #contact_us {
    //     padding-left: 10px;
    // }
    .carousel-control-next{ 
        padding-left: 0px !important;
    }
    .carousel-control-prev { 
        padding-right: 0px !important;
    }
    #welcome {
        background-position: right center;
    }
}



@media only screen and (max-width: 475px) {
    #law-firm-header {
        padding-top: 60px;
        font-size: 18px !important;
        font-weight: 600 !important;
        line-height: 1.8;
        letter-spacing: 1px !important;
    }
    #areas-of-practice-select-label { 
        font-size: 14px !important; 
        padding-right: 5px;
    }
    .css-7hcsmk-container {
        width: 155px !important;
    }
    .css-wpajnj-placeholde, .area_selection {
        padding-left: 2px;
        padding-right: 2px;
        font-size: 3vw !important;
        white-space: nowrap;
    }
    #area_of_practice_container > div > div.css-wqmjsd-control {
        font-size: 2.9vw !important;
    }
    .css-124yycj-option  { 
        font-size: 2.9vw !important;
        white-space: nowrap;
     }
    #logo-img {
        padding-bottom: 0px !important;
        padding-left: 10px !important;
    }
    .p-2 {
        padding-bottom: .2rem !important;
    }
    #logo-container { 
        padding-left: 3px;
    }

    #area_of_practice_container {
        padding-top: 10px;
    }
    .carousel-control-next, .carousel-control-prev {
        font-size: 3vh !important;
    }
    .testimonial-item {
        width: 80%;
    }
    .testimonial-quote {
        color: #ffffffce;
        font-style: italic;
        font-family: 'Source Sans Pro', sans-serif;
        font-size: 14px;
    }
    .carousel-control-next { 
        padding-left: 20px !important;
    }
    .carousel-control-prev { 
        padding-right: 20px !important;
    }
    #basic-navbar-nav {
        padding-left: 20px !important;
    }
    .dropdown-menu {
        margin-right: 20px !important;
    }
    .dropdown-item {
        padding: 10px !important;
    }
    .next-line {
        padding-left: 25px;
    }
}

@media only screen and (max-width: 370px) {
    #law-firm-header {
        padding-top: 60px;
        font-size: 15px !important;
        font-weight: 600 !important;
        line-height: 1.8;
        letter-spacing: 2px !important;
    }
    #areas-of-practice-select-label { 
        font-size: 12px !important; 
        padding-right: 10px;
    }
    .css-7hcsmk-container {
        width: 155px !important;
    }
    .css-wpajnj-placeholde, .area_selection {
        padding-left: 2px;
        padding-right: 2px;
        font-size: 3vw !important;
        white-space: nowrap;
    }
    #area_of_practice_container > div > div.css-wqmjsd-control {
        font-size: 2.9vw !important;
    }
    .css-124yycj-option  { 
        font-size: 2.9vw !important;
        white-space: nowrap;
     }
    #logo-img {
        padding-bottom: 0px !important;
        padding-left: 10px !important;
    }
    .p-2 {
        padding-bottom: .2rem !important;
    }
    #logo-container { 
        padding-left: 3px;
    }

    #area_of_practice_container {
        padding-top: 10px;
    }
    .carousel-control-next, .carousel-control-prev {
        font-size: 3vh !important;
    }
    .testimonial-item {
        width: 80%;
    }
    .testimonial-quote {
        color: #ffffffce;
        font-style: italic;
        font-family: 'Source Sans Pro', sans-serif;
        font-size: 14px;
    }
    .carousel-control-next { 
        padding-left: 20px !important;
    }
    .carousel-control-prev { 
        padding-right: 20px !important;
    }
    #basic-navbar-nav {
        padding-left: 20px !important;
    }
    .dropdown-menu {
        margin-right: 20px !important;
    }
    .dropdown-item {
        padding: 10px !important;
    }
    .next-line {
        padding-left: 25px;
    }
}